import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, INIT, MetaReducer} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromClientUser from './client-user.reducer';
import { ClientUserAction } from './client-user.reducer';

export interface RootState {
  [fromClientUser.clientUserFeatureKey]: fromClientUser.State;
}

export const reducers: ActionReducerMap<RootState> = {
  [fromClientUser.clientUserFeatureKey]: fromClientUser.reducer,
};

export function clearState(reducer) {
  return (state, action) => {
    if ( action != null && action.type === ClientUserAction.ClearClientUser.type) {
      return reducer( undefined, {type: INIT});
      // state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<RootState>[] = !environment.production ? [clearState] : [clearState];

export const selectClientUserState = createFeatureSelector< fromClientUser.State>(fromClientUser.clientUserFeatureKey);

export const selectClientUser = createSelector(selectClientUserState, fromClientUser.getClientUser);

